<template>
  <div>
    <NoahMasterLevels />
    <NoahMasters />
  </div>
</template>

<script>
import NoahMasterLevels from './NoahMasterLevels.vue';
import NoahMasters from './NoahMasters.vue';

export default {
  components: {
    NoahMasterLevels,
    NoahMasters
  }
};
</script>
