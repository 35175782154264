<template>
    <div class="register-container">
      <div class="register-form">
        <h2>Kayıt Ol</h2>
        <form @submit.prevent="handleRegister">
          <div class="form-group">
            <label for="email">E-posta</label>
            <input type="email" id="email" v-model="email" required />
          </div>
          <div class="form-group">
            <label for="username">Kullanıcı Adı</label>
            <input type="text" id="username" v-model="username" required />
          </div>
          <div class="form-group">
            <label for="fullname">İsim Soyisim</label>
            <input type="text" id="fullname" v-model="fullname" required />
          </div>
          <div class="form-group">
            <label for="phone">Telefon Numarası</label>
            <input type="tel" id="phone" v-model="phone" required />
          </div>
          <div class="form-group">
            <label for="password">Bir Şifre Belirleyin</label>
            <input type="password" id="password" v-model="password" required />
          </div>
          <div class="form-group">
            <label for="confirmPassword">Şifreyi Tekrar Girin</label>
            <input type="password" id="confirmPassword" v-model="confirmPassword" required />
          </div>
          <button type="submit">Kayıt Ol</button>
        </form>
        <div class="register-links">
          <p>
            Zaten üye misiniz? <span @click="navigateToLogin" class="link">Giriş Yap</span>
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        email: '',
        username: '',
        fullname: '',
        phone: '',
        password: '',
        confirmPassword: ''
      };
    },
    methods: {
      async handleRegister() {
        if (this.password !== this.confirmPassword) {
          alert('Şifreler eşleşmiyor.');
          return;
        }
  
        try {
          const response = await axios.post('/auth/register', {
            email: this.email,
            username: this.username,
            fullname: this.fullname,
            phone: this.phone,
            password: this.password
          });
  
          if (response.data.success) {
            alert('Başarıyla kayıt oldunuz. Şimdi giriş yapabilirsiniz.');
            this.$router.push({ path: '/login' });
          } else {
            alert('Kayıt başarısız. Lütfen bilgilerinizi kontrol edin.');
          }
        } catch (error) {
          console.error('Kayıt yapılırken hata oluştu:', error);
          alert('Bir hata oluştu. Lütfen tekrar deneyin.');
        }
      },
      navigateToLogin() {
        this.$router.push({ path: '/login' });
      }
    }
  };
  </script>
  
  <style scoped>
  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
  }
  
  .register-form { 
    padding: 2em;
    background: #050505;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  h2 {
    margin-bottom: 1.5em; 
  }
  
  .form-group {
    margin-bottom: 1.5em;
    text-align: left;
  }
  
  label {
    display: block;
    margin-bottom: 0.5em; 
  } 
  
  button {
    width: 100%;
    padding: 0.75em;
    border: none;
    border-radius: 5px;
    background-color: #065959;
    color: white;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #187774;
  }
  
  .register-links {
    margin-top: 2em; 
    color: #555;
  }
  
  .link {
    color: #065959;
    cursor: pointer;
    text-decoration: none;
  }
  
  .link:hover {
    color: #187774;
  }
  </style>
  