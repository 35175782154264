<template>
    <div>
      <div class="education-list">
        <div class="education-list-head">
          <p class="egitim-title">ÜCRETSİZ ONLINE EĞİTİMLER</p>
          <p class="egitim-desc">Online eğitimlerimiz, güncel bilgilere ulaşmanız ve ihtiyaçlarınıza uygun içeriklere erişebilmeniz amacıyla hazırlanmıştır. <br>
            Eğitim programları düzenli olarak gözden geçirilir ve yenilenir. 
            Amacımız, her seviyeden katılımcıya faydalı olabilecek, kapsamlı ve sürekli güncellenen kaynaklar sunmaktır.
          </p>
        </div>
        
        <div
          v-for="education in educations"
          :key="education.education_id"
          class="education-item"
          :class="{
            'education-inactive': (!education.is_active) || (education.is_loggedin && !loggedIn)
          }"
          @click="(education.is_active && (!education.is_loggedin || loggedIn)) ? navigateToEducation(education.education_id) : null"
        >
  
        <span>{{ education.name }}</span>
          <span 
            v-if="education.is_loggedin && !loggedIn"
            @click.stop="goLogin"
            style="cursor: pointer; color: #187774; "
          >
            Lütfen Giriş Yapınız
          </span>
          
          <span v-if="!education.is_active && !education.is_loggedin" class="coming-soon">Çok Yakında</span>
        </div>
        <p v-if="educations.length === 0">Lütfen Bekleyin...</p>
  
        <div class="education-list-footer">
          <p class="test-et">KENDİNİ TEST ET</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        educations: [],
        loggedIn: false
      };
    },
    methods: {
      async fetchEducations() {
        try {
          const response = await axios.get('/auth/educations');
          this.educations = response.data.educations;
        } catch (error) {
          console.error('Eğitimler alınırken hata oluştu:', error);
        }
      },
      navigateToEducation(education_id) {
        this.$router.push({ name: 'EducationDetail', params: { educationId: education_id } });
      },
      goLogin() {
        this.$router.push({ path: '/login' });
      }
    },
    mounted() {
      this.loggedIn = !!localStorage.getItem('token');
      this.fetchEducations();
    },
  };
  </script>
  
  <style scoped>
  .education-list {
    display: flex;
    align-items: center;
    min-height: 70vh;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 50px 20px;
  }
  
  .education-item {
    background-color: #070707;
    flex: 1 1 calc(25% - 20px);
    max-width: 300px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    font-size: 1.2em;
    padding: 10px;
    font-weight: 400;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 10px;
    border: 1px solid #353535;
    color: white;
  }
   
  .education-inactive {
    background-color: #0a0a0a;
    cursor: default; 
    color: #dadada;
  }
  .education-item:not(.education-inactive):hover {
    transform: scale(1.05);
    box-shadow: 0 6px 6px rgba(2, 48, 50, 0.6);
  }
  
  .coming-soon {
    font-size: 0.9em;
    color: #474747;
    margin-top: 5px;
  }
  .education-list-head{ 
    margin-top: 5em;
    width: 100%;
    text-align: center;
  }
  .egitim-title {
    font-size: 1.8em;
    font-weight: 300; 
  }
  .egitim-desc { 
    font-weight: 400; 
    color: #757575;
  }
  
  .education-list-footer{ 
    margin: 5em 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .test-et{
    background-color: #070707;
    padding: 2em;
    font-size: 1.2em;
    border: 1px solid #065959;
    max-width: 15em;
    border-radius: 1em;
    color: #065959;
    transition: transform 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  }
  .test-et:hover { 
    cursor: pointer;
    transform: scale(1.05)
  }
  
  @media (max-width: 1024px) {
    .education-item {
      flex: 1 1 calc(33.33% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .education-item {
      flex: 1 1 calc(48% - 20px);
      font-size: 1em;
    }
  }
  
  @media (max-width: 359px) {
    .education-item {
      flex: 1 1 calc(100% - 20px);
    }
  }
  </style>
  