<template>
    <div class="top-menu">
      <div 
        v-if="!isLoggedIn" 
        class="topmenu-button login-button" 
        @click="goToLogin"
        title="Giriş Yap"
        role="button"
        tabindex="0"
      >
        <img src="/images/login-icon.svg" alt="Login Icon" />
      </div>
      <div 
        v-if="isLoggedIn" 
        class="topmenu-button profile-button" 
        @click="goToProfile"
        title="Profil"
        role="button"
        tabindex="0"
      >
        <img src="/images/profile-icon.svg" alt="Profile Icon" />
      </div>
      <div 
        v-if="isLoggedIn" 
        class="topmenu-button logout-button" 
        @click="logout"
        title="Çıkış Yap"
        role="button"
        tabindex="0"
      >
        <img src="/images/logout-icon.svg" alt="Logout Icon" />
      </div>
    </div>
  </template>
  
  <style scoped>
  .top-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top: -5px;
    right: 10px;
    z-index: 1000;
  }
  
  .topmenu-button {
    max-width: 35px;
    height: 45px;
    border: none;
    background: none;
    cursor: pointer; 
    transition: transform 0.2s, box-shadow 0.2s;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0  10px;
    padding-bottom: 10px;
    opacity: 0.5;
  }
  
  .topmenu-button:hover {
    transform: translateY(5px); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0.9;
  }
  
  .login-button {
    background-color: #006400;
  }
  
  .profile-button {
    background-color: #023032;
  }
  
  .logout-button {
    background-color: #8b0000;
  }
  
  .topmenu-button img {
    width: 60%;   
    top: 5px;
  }
  </style>
  
  
  <script>
  export default {
    data() {
      return {
        isLoggedIn: false,
        tokenCheckInterval: null, 
      };
    },
    mounted() {
      this.checkLoginStatus(); 
      this.startTokenCheck(); 
    },
    beforeUnmount() {
      this.clearTokenCheck(); 
    },
    methods: {
      checkLoginStatus() {
        this.isLoggedIn = !!localStorage.getItem('token');
      },
      startTokenCheck() {
        this.tokenCheckInterval = setInterval(() => {
          this.checkLoginStatus();
        }, 1000); 
      },
      clearTokenCheck() {
        if (this.tokenCheckInterval) {
          clearInterval(this.tokenCheckInterval);
        }
      },
      goToLogin() {
        this.$router.push('/login');
      },
      goToProfile() {
        this.$router.push('/profile');  
      },
      logout() {
        localStorage.removeItem('token');
        this.checkLoginStatus();
        this.$router.push('/login');
      },
    },
  };
  </script>
  


  