<template>
    <div class="static-page"> 
      <div class="title" v-html="staticPage.title"></div>
      <div class="static-page-content" v-html="staticPage.content"></div>
      <div v-if="!staticPage.title && !loading" class="error-message">
        <p>Sayfa bulunamadı veya bir hata oluştu.</p>
      </div>
      <div v-if="loading" class="loading-message">
        <p>Yükleniyor...</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        staticPage: {}, 
        loading: false, 
      };
    },
    methods: {
      async fetchStaticPage() {
        this.loading = true;
        try {
          const response = await axios.get("/auth/staticpage/title/Çerez Politikası");
          this.staticPage = response.data.staticPage || {};
          console.log("Static page alındı:", this.staticPage);
        } catch (error) {
          console.error("Static page alınırken hata oluştu:", error);
        } finally {
          this.loading = false;
        }
      },
    },
    mounted() {
      this.fetchStaticPage();
    },
  };
  </script>
  
  <style scoped>
  .static-page { 
    min-height: 70vh;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    margin: 0 auto; 
    text-align: left; 
    line-height: 1.6; 
    padding: 130px 10px; 
  }
  
  .title {
    font-size: 2em; 
    margin-bottom: 20px; 
  }
  
  .static-page-content {
    font-size: 1.1em; 
  }
  
  .error-message,
  .loading-message {
    text-align: center;
    font-size: 1.2em;
    color: #888;
  }
  @media (max-width: 768px) {
    .static-page {   
    padding: 100px 10px; 
  }
}
  </style>
  