<template>
  <div class="category-list">
    <NoahLoader :isLoading="isLoading" />
    <template v-if="!isLoading">
      <div 
        v-for="category in categories" 
        :key="category.id" 
        class="category-item" 
        @click="navigateToCategory(category.short_link)"
      >
        <img 
          :src="require(`@/assets/icon/navigation/${category.short_link}.svg`)" 
          :alt="category.name" 
          class="category-icon" 
        />
        {{ category.name }}  
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import NoahLoader from './NoahLoader.vue';
import CryptoJS from 'crypto-js';  

export default {
  components: {
    NoahLoader
  },
  data() {
    return {
      categories: [],
      isLoading: true
    };
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get('/auth/main-categories');
        this.categories = response.data.mainCategories.sort((a, b) => a.order_no - b.order_no);
      } catch (error) {
        console.error('Kategoriler alınırken hata oluştu:', error);
      } finally {
        this.isLoading = false;
      }
    },
    navigateToCategory(short_link) {
      if (short_link === 'satinal') {
        const token = localStorage.getItem('token');
        if (token) {
          const encryptedToken = CryptoJS.AES.encrypt(token, 'your-secret-key').toString(); 
          window.open(`https://shop.noahpmu.com/?auth=${encodeURIComponent(encryptedToken)}`, '_blank');
        } else {
          window.open('https://shop.noahpmu.com/', '_blank');
        }
      } else {
        this.$router.push(`/${short_link}`);
      }
    }
  },
  mounted() {
    this.fetchCategories();
  }
};
</script>




<style scoped>
.category-list {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center; 
  gap: 10px; 
  padding: 50px 20px;
}

.category-item { 
  background-color: #050505;
  flex: 1 1 calc(25% - 20px);  
  max-width: 300px; 
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  font-size: 1.2em;
  padding: 7px;
  font-weight: 300;
  transition: transform 0.2s ease, box-shadow 0.3s ease, color 0.2s ease; 
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(2, 48, 50, 0);  
  border: 1px solid transparent;
}
.category-item img {   
  opacity: 0.8; 
}
.category-item:hover {  
  color: white;
  transform: scale(1.05); 
  opacity: 1;
  box-shadow: 0 8px 18px rgba(2, 48, 50, 0.5); 
}

.category-item:hover img {   
  opacity: 1; 
}

.category-icon {
  max-height: 2em;  
  margin: 10px;  
}

@media (max-width: 1024px) {
  .category-item {
    flex: 1 1 calc(33.33% - 20px);  
  }
}
 
@media (max-width: 768px) {
  .category-item {
    flex: 1 1 calc(50% - 22px); 
    box-shadow: 0 8px 18px rgba(2, 48, 50, 0.2); 
    font-size: 1em;
  }
  .category-icon {
    max-height: 2em; 
  }
}  
@media (max-width: 359px) {
  .category-item {
    flex: 1 1 calc(100% - 20px); 
  }
}

</style>
