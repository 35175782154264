<template>
  <div :class="{ 'menu': true, 'open': isOpen }" @click="toggleMenu" @touchstart="startTouch" @touchmove="moveTouch">
    <div class="menu-content"> 
      <p><img src="../assets/icon/side-menu/shop-icon.svg" @click="goToShop" /> </p>
    </div>
    <div class="menu-content bottom-icons">
      <p><img src="../assets/icon/side-menu/docs-icon.svg" @click="navigateTo('/sertifikalar')" /> </p>
      <p><img src="../assets/icon/side-menu/download-icon.svg" @click="navigateTo('/mobiluygulamalar')" /> </p>
      <p><img src="../assets/icon/side-menu/whatsapp-icon.svg" @click="openWhatsApp" /> </p>
      <p><img src="../assets/icon/side-menu/instagram-icon.svg" @click="openInstagram" /> </p>
    </div>
    <div class="menu-tab"> 
      <img :class="{ 'rotated': isOpen }" src="../assets/icon/side-menu-icon.svg" class="side-menu-icon" /> 
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideMenu',
  data() {
    return {
      isOpen: false,
      startX: 0
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    startTouch(e) {
      this.startX = e.touches[0].clientX;
    },
    moveTouch(e) {
      if (e.touches[0].clientX - this.startX > 50) { 
        this.isOpen = true;
      } else if (this.startX - e.touches[0].clientX > 50) { 
        this.isOpen = false;
      }
    },
    goToShop() {
      const token = localStorage.getItem('token');
      if (token) {
        const encryptedToken = btoa(token);  
        window.open(`https://shop.noahpmu.com/?auth=${encodeURIComponent(encryptedToken)}`, '_blank');
      } else {
        window.open('https://shop.noahpmu.com/', '_blank');
      }
    },
    navigateTo(path) {
      this.$router.push(path);
    },
    openWhatsApp() {
      window.open('https://wa.me/+905331348139', '_blank');
    },
    openInstagram() {
      window.open('https://www.instagram.com/noahpmushop', '_blank');
    }
  }
};
</script>
