
<template>
  <div>
    <NoahMasterLevels /> 
  </div>
</template>



<script>
import NoahMasterLevels from './NoahMasterLevelsforEdu.vue'; 

export default {
  components: {
    NoahMasterLevels,  
  }
};
</script>
