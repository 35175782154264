<template>
    <div>
      <div class="faq-accordion">
        <p class="faq-main-title ">SIKÇA SORULAN SORULAR (SSS)</p>
        <div v-for="(category, categoryName) in groupedFaqs" :key="categoryName">
          <p class="faq-category">{{ categoryName }}</p>
          <div v-for="(item, index) in category" :key="index" class="faq-item">
            <div class="faq-title" @click="toggle(categoryName, index)">
              <div :class="['icon', { open: isOpen(categoryName, index) }]">
                <span v-if="isOpen(categoryName, index)">&#8722;</span>
                <span v-else>&#43;</span>
              </div>
              <span>{{ item.question }}</span>
            </div>
            <div :class="['faq-content', { open: isOpen(categoryName, index) }]">
              <p>{{ item.answer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        faqs: [],  
        activeIndex: {}, 
      };
    },
    computed: { 
      groupedFaqs() {
        const grouped = {};
        this.faqs.forEach((faq) => {
          const category = faq.category || "Diğer";  
          if (!grouped[category]) grouped[category] = [];
          grouped[category].push(faq);
        }); 
        Object.keys(grouped).forEach((category) => {
          grouped[category].sort((a, b) => (a.order_number || 0) - (b.order_number || 0));
        });
     
        const sortedGrouped = {};
        Object.keys(grouped)
          .sort((a, b) => a.localeCompare(b)) 
          .forEach((key) => {
            sortedGrouped[key] = grouped[key];
          });
    
        return sortedGrouped;
      },
    },
    
    methods: {
      async fetchFaqs() {
        try {
          const response = await axios.get("/auth/faqs");
          this.faqs = response.data.faqs || []; 
        } catch (error) {
          console.error("SSS verileri alınırken hata oluştu:", error);
        }
      },
      toggle(category, index) {
        if (!this.activeIndex[category]) {
          this.activeIndex[category] = null; 
        }
        this.activeIndex[category] =
          this.activeIndex[category] === index ? null : index;
      },
      isOpen(category, index) { 
        return this.activeIndex[category] === index;
      },
    },
    mounted() {
      this.fetchFaqs();
    },
  };
  </script>
  
  <style scoped>
  .faq-accordion {  
    display: flex;
    flex-direction: column;
    margin: 0 auto;  
    justify-content: center;
    text-align: center;
    padding-top: 8em;
  }
  .faq-main-title {
    font-size: 1.8em;
    font-weight: 300; 
    margin-bottom: 0;
  }
  
  .faq-category {
    margin-top: 1.5em;
    font-size: 1.2em; 
    font-weight: 400; 
    text-align: center; 
    border-bottom: 1px solid #065959;
    padding-bottom: 0.5em;
    width: 100%;
    color: #065959;
  }
  
  .faq-item {
    margin-bottom: 1em; 
  }
  
  .faq-title {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    padding: 0.5em 0;
    font-weight: 300;
    font-size: 1em;
  }
  
  .icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid #353535; 
    color: #353535;
    background-color: #0a0a0a; 
    transition: background-color 0.3s, color 0.3s;
  }
  
  .icon.open {
    background-color: black; 
    color: #065959;
  }
  
  .faq-content {
    max-height: 0;
    overflow: hidden;
    text-align: left;
    transition: max-height 0.2s ease-in-out, padding 0.2s ease-in-out;
  }
  
  .faq-content.open {
    max-height: 200px;
    padding: 1em 0;
    text-align: left;
  }
  
  @media (max-width: 600px) {
    .faq-category {
      font-size: 1.3em;
    }
  }
  </style>
  