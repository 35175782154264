import { createRouter, createWebHistory } from 'vue-router'; 
import NoahMain from './components/NoahMain.vue';
import ProductMain from './components/ProductMain.vue';
import Microbladings from './components/MicroBladings.vue';
import BrowPigments from './components/BrowPigments.vue';
import LipPigments from './components/LipPigments.vue';
import MicroDetail from './components/MicroDetail.vue';
 
import ShowRoom from './components/ShowRoom.vue';

import UcretsizEgitim from './components/education/UcretsizEgitim.vue';
import EducationDetail from './components/education/EducationDetail.vue';

import KendiniTestEt from './components/education/UserTests.vue';
import MasterClassEgitim from './components/MasterClassEgitim.vue';
import NoahMasterWorld from './components/NoahMasterWorld.vue';

import UluslararasiSertifikalar from './components/UluslararasiSertifikalar.vue';

import MobilUygulamalar from './components/application/AppMain.vue';
import NoahDuyurular from './components/NoahDuyurular.vue'; 
import NoahHakkinda from './components/NoahHakkinda.vue';
import VeriGizliligi from './components/VeriGizliligi.vue'; 
import KullanimKosullari from './components/KullanimKosullari.vue'; 
import CerezPolitikasi from './components/CerezPolitikasi.vue'; 
import UserAgreements from './components/UserAgreements.vue'; 
import SsS from './components/FaqAccordion.vue'; 

import UserLogin from './components/UserLogin.vue'; 
import UserRegister from './components/UserRegister.vue'; 

const routes = [ 
  { path: '/', component: NoahMain, meta: { title: 'Noah PMU - Premium Pigments' } },
  { path: '/urunler', component: ProductMain, meta: { title: 'Noah PMU - Ürünler' } }, 
  { path: '/showroom', component: ShowRoom, meta: { title: 'Noah PMU - Showroom' } },

  { path: '/ucretsizegitim', component: UcretsizEgitim, meta: { title: 'Noah PMU - Ücretsiz Eğitim' } },
  {
    path: '/educations/:educationId',
    name: 'EducationDetail',
    component: EducationDetail,
    props: true,
    meta: { title: 'Noah PMU - Eğitim Detayı' },
  },
  { path: '/login', component: UserLogin, meta: { title: 'Noah PMU - Giriş yap' } },
  { path: '/kayitol', component: UserRegister, meta: { title: 'Noah PMU - Giriş yap' } },
  { path: '/kendinitestet', component: KendiniTestEt, meta: { title: 'Noah PMU - Kendini Test Et' } },
  { path: '/masterclassegitim', component: MasterClassEgitim, meta: { title: 'Noah PMU - MasterClass Eğitim' } },
  { path: '/noahmasterworld', component: NoahMasterWorld, meta: { title: 'Noah PMU - Master World' } },
  { path: '/sertifikalar', component: UluslararasiSertifikalar, meta: { title: 'Noah PMU - Sertifikalar' } },
  { path: '/mobiluygulamalar', component: MobilUygulamalar, meta: { title: 'Noah PMU - Mobil Uygulamalar' } },
  { path: '/etkinlikler', component: NoahDuyurular, meta: { title: 'Noah PMU - Duyurular' } },
 
  { path: '/sss', component: SsS, meta: { title: 'Noah PMU - Sıkça Sorulan Sorular' } },
  { path: '/hakkimizda', component: NoahHakkinda, meta: { title: 'Noah PMU - Hakkımızda' } },
  { path: '/veri-gizliligi', component: VeriGizliligi, meta: { title: 'Noah PMU - Veri Gizliliği Politikası' } },
  { path: '/kullanim-kosullari', component: KullanimKosullari, meta: { title: 'Noah PMU - Kullanım Koşulları' } },
  { path: '/cerez-politikasi', component: CerezPolitikasi, meta: { title: 'Noah PMU - Çerez Politikası' } },
  { path: '/sozlesmeler', component: UserAgreements, meta: { title: 'Noah PMU - Sözleşme Merkezi' } },

  { path: '/lippigments', component: LipPigments, meta: { title: 'Noah PMU - Lip Pigments' } },
  { path: '/browpigments', component: BrowPigments, meta: { title: 'Noah PMU - Brow Pigments' } },
  { path: '/microbladingpigments', component: Microbladings, meta: { title: 'Noah PMU - Microblading Pigments' } },
  
  { path: '/microdetail/:title', name: 'MicroDetail', component: MicroDetail, props: true, meta: { title: 'Noah PMU - Micro Detay' } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) { 
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});
 
router.beforeEach((to, from, next) => {
  const defaultTitle = 'Noah PMU';
  document.title = to.meta.title || defaultTitle;
  next();
});

export default router;
