<template>
  <div class="selected-detail">
    <p class="category-title">{{ details?.sub_category_name }}</p>
    <p class="title">{{ details?.name }}</p>

    <div class="product-header">
      <img v-if="details?.main_image" :src="details.main_image" alt="Main Image" class="main-img"/>
      <p class="description">{{ details?.description }}</p>
    </div> 
    <div class="sub-img">
      <div class="images"> 
         <img v-if="details?.detail_image" :src="details.detail_image" alt="Detail Image" />
       </div>

       <div v-if="details?.background_image" class="background-image">
         <img :src="details.background_image" alt="Background Image" />
       </div>
    </div> 
 
    <div v-if="details?.documents && details.documents.length" class="document-icons">
      <a v-for="document in details.documents" :key="document.document_id" :href="document.document_link" target="_blank">
        <img :src="document.icon_link" alt="Document Icon" class="document-icon"/>
      </a>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import axios from "axios";

export default {
  name: "SelectedDetail",
  props: {
    minorCategoryId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const details = ref(null);

    const fetchDetails = async () => {
      try {
        const response = await axios.get(`/auth/minor-category/details/${props.minorCategoryId}`);
        details.value = response.data.minorCategory; 
      } catch (error) {
        console.error("Detaylar yüklenirken hata:", error);
      }
    };

    watch(
      () => props.minorCategoryId,
      () => {
        fetchDetails();
      },
      { immediate: true }
    );

    onMounted(() => {
      fetchDetails();
    });

    return {
      details,
    };
  },
};
</script>

<style>
.selected-detail { 
  line-height: 1.6;
  margin: 20px;
}

.title {
  font-size: 4em;
  font-weight: 300;
  margin: 0;
}

.category-title {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0;
}

.product-header { 
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.main-img {
  max-width: 300px;
}

.description {
  max-width: 1200px;
  font-size: 1.2em;
}

.sub-img { 
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.images img {
  max-width: 380px; 
}

.background-image img {
  max-width: 380px; 
}
 
.document-icons {
  margin: 50px 0;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.document-icon {
  display: flex;
  width: 100px;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.document-icon:hover {
  transform: scale(1.1);
}
</style>
