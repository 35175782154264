<template>
  <div>
    <div class="sertifikalar-list">
      <div class="sertifikalar-list-head">
        <p class="sertifikalar-title">ULUSLARARASI SERTİFİKALAR</p>
        <p class="sertifikalar-desc">
          Noah PMU olarak tüm ürünlerimizde uluslararası yüksek standartlar kullanmaktayız. <br>
          Sertifikalarımızdan bazıları aşağıda listelenmiştir. <br> Daha fazlası için <b>info@noahpmu.com</b> ile iletişime geçebilirsiniz.
        </p>
      </div> 
      <div v-if="documents && documents.length" class="document-icons">
        <div v-for="document in documents" :key="document.document_id" class="document-item">
          <a :href="document.document_link" target="_blank">
            <img :src="document.icon_link" alt="Document Icon" class="document-icon" />
          </a>
          <p class="document-name">{{ document.name }}</p>
        </div>
      </div>
      <div v-else class="no-documents">
        <p>Henüz görüntülenecek doküman bulunmamaktadır.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      documents: [],  
    };
  },
  methods: {
    async fetchDocuments() {
      try {
        const response = await axios.get("/auth/generaldocuments");
        this.documents = response.data.documents || [];
        console.log("Dokümanlar başarıyla alındı:", this.documents);
      } catch (error) {
        console.error("Dokümanlar alınırken hata oluştu:", error);
      }
    },
  },
  mounted() {
    this.fetchDocuments(); 
  },
};
</script>

<style scoped>
.sertifikalar-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70vh;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 50px 20px; 
}

.sertifikalar-list-head {
  margin-top: 5em;
  width: 100%;
  text-align: center;
}

.sertifikalar-title {
  font-size: 1.9em;
  margin-bottom: 0;
  font-weight: 300;
}

.sertifikalar-desc {
  font-size: 1.2em;
  font-weight: 400;
  color: #757575;
  margin-top: 0.5em;
}

.document-icons {
  margin: 50px 0;
  display: flex;
  gap: 50px;
  align-items: flex-start;
  flex-wrap: wrap; 
}

.document-item {
  display: flex;
  align-items: center;
  flex-direction: column; 
  max-width: 120px; 
}

.document-icon {
  width: 100px;
  height: 100px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.document-icon:hover {
  transform: scale(1.1);
}

.document-name {
  margin-top: 10px;
  font-size: 1.2em; 
  text-align: center;
}

.no-documents {
  margin-top: 30px;
  font-size: 1.2em;
  color: #757575;
}

@media (max-width: 768px) {
  .document-item { 
    flex: 1 1 calc(48% - 20px);
    font-size: 1em; 
}
  .document-name { 
  font-size: 1.1em;  
}
.sertifikalar-desc {
  font-size: 1em;
  font-weight: 400; 
}
.document-icon {
  width: 90px; 
}
}
</style>
