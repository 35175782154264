<template>
  <div class="noah-masters">  
    <div class="masters-container">
      <div v-for="master in masters" :key="master.master_id" class="master-card">
        <img v-if="master.image_link" :src="master.image_link" alt="Master Fotoğrafı" class="master-image" />

        <div class="master-level">
          <img v-if="master.level_icon" :src="master.level_icon" alt="Seviye İkonu" class="level-icon" />
          <p class="level-title">{{ master.level }}</p>
        </div>

        <div class="master-details">
          <p class="master-name">{{ master.name }}</p>
          <p class="master-info" v-if="master.phone"><img class="item-icon" src="../assets/icon/world/phone.svg" />  {{ master.phone }}</p>
          <p class="master-info" v-if="master.instagram"><img class="item-icon" src="../assets/icon/world/instagram.svg" />  {{ master.instagram }}</p>
          <p class="master-info" v-if="master.country"><img class="item-icon" src="../assets/icon/world/location.svg" />  {{ master.country }} / {{ master.city }}</p> 
          <p class="master-bio" v-if="master.bio">{{ master.bio }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      masters: [], 
    };
  },
  methods: {
    async fetchMasters() {
      try {
        const response = await axios.get("/auth/noah-masters");
        this.masters = response.data.masters || [];
      } catch (error) {
        console.error("Noah masterlar alınırken hata oluştu:", error);
      }
    },
  },
  mounted() {
    this.fetchMasters(); 
  },
};
</script> 

<style scoped>
.noah-masters {
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 320px;
} 
.masters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.item-icon{
  max-width: 1em;
  max-height: 1em;
}

.master-card {  
  min-width: 320px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease;
  background-color: #050505;
  padding: 5px;
}

.master-card:hover {
  transform: translateY(-5px);
  background-color: black;
}

.master-image {
  width: 100%;
  max-height: 380px;
  object-fit: cover;
}

.master-level { 
  padding: 10px;
}

.level-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-top: -120px;
  background-color: black;
  border-radius: 20%;
}

.level-title {
  font-size: 1em;
  font-weight: 200;
  margin: 0 0 5px 0;
}

.master-details {  
  margin-top: -20px;
  padding: 5px;
}

.master-name {
  font-size: 1.2em; 
  margin: 0 0 5px 0;
  font-weight: 500;
}

.master-info {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  font-size: 1em; 
  margin: 5px 0;
}

.master-bio {
  font-size: 0.9em; 
  margin-top: 10px;
  text-align: justify;
}

@media (max-width: 560px) {
  .masters-container {
    flex-wrap: wrap;
    gap: 5px; 
  }

  .master-card {
    width: calc(48% - 10px); 
    min-width: unset; 
  }

  .master-image {
    border-radius: 8px;
  }
} 
</style> 