<template>
    <div>
      <div class="master-levels-list"> 
        <div v-if="masterLevels && masterLevels.length" class="master-levels-icons">
          <div v-for="level in masterLevels" :key="level.level_id" class="master-level-item">
            <img
              v-if="level.icon_link"
              :src="level.icon_link"
              alt="Master Level Icon"
              class="master-level-icon"
            />
            <p class="master-level-name">{{ level.title }}</p> 
          </div>
        </div> 
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        masterLevels: [],  
      };
    },
    methods: {
      async fetchMasterLevels() {
        try {
          const response = await axios.get("/auth/master-levels");
          this.masterLevels = response.data.masterLevels || [];
          console.log("Master seviyeleri başarıyla alındı:", this.masterLevels);
        } catch (error) {
          console.error("Master seviyeleri alınırken hata oluştu:", error);
        }
      },
    },
    mounted() {
      this.fetchMasterLevels(); 
    },
  };
  </script>
  
  <style scoped>
  .master-levels-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 70vh;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 50px 20px;
  }
   
  .master-levels-title {
    font-size: 1.9em;
    margin-bottom: 0;
    font-weight: 300;
  }
   
  
  .master-levels-icons {
    margin: 50px 0;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .master-level-item { 
    min-width: 220px;
    display: flex;  
      flex-direction: column;
      justify-content: center; 
      align-items: center;
  }
  
  .master-level-icon {
    width: 120px;
    height: auto;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .master-level-icon:hover {
    transform: scale(1.1);
  } 
  
  @media (max-width: 768px) {
    .master-level-name {
      font-size: 1.1em;
    }
    .master-levels-desc {
      font-size: 1em;
      font-weight: 400;
    }
    .master-level-icon {
      max-width: 100px; 
    }
    .master-levels-icons {
      display: flex; 
      width: 50% -20px;
      justify-content: center; 
      align-items: center;
    }
    .master-level-item { 
      min-width: 120px;
      max-width: 130px;
     }
  }
  </style>
  