<template>
  <div id="app">
    <MainHeader />
    <TopMenu />
    <SideMenu />
    <div class="content">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" class="router-view"></component>
        </transition>
      </router-view>
    </div>
    <MainFooter />
    <MaintenanceModal v-if="isMaintenanceMode" />
  </div>
</template>

<script>
import MainHeader from './components/MainHeader.vue';
import MainFooter from './components/MainFooter.vue';
import SideMenu from './components/SideMenu.vue'; 
import MaintenanceModal from './components/MaintenanceModal.vue';
import axios from 'axios';
import TopMenu from './components/TopMenu.vue'; 

export default {
  name: 'App',
  components: {
    MainHeader,
    MainFooter,
    SideMenu,
    MaintenanceModal,
    TopMenu,
  },
  data() {
    return {
      isMaintenanceMode: true,
    };
  },
 created() {
   this.checkMaintenanceMode();
 },
 methods: {
   async checkMaintenanceMode() {
     try {
       const response = await axios.get('/auth/web-status/1');
       this.isMaintenanceModea = !response.data?.is_active;
     } catch (error) {
       console.error('Error checking maintenance mode:', error); 
       this.isMaintenanceMode = true;
     }
   },
 },
};
</script>