<template>
  <div>

    <div class="head">
      <p class="title">DUYURULAR</p>
      <p>Genel duyuru ve güncellemeler aşağıda listelenmektedir. Daha fazla detay için bizimle iletişime geçebilirsiniz.</p>
    </div>

    <div class="announcements-container">
    <div
      v-for="announcement in announcements"
      :key="announcement.announcement_id"
      :class="['announcement-card', announcement.type]"
    >
      <div class="announcement-header">
        <img
          v-if="announcement.is_important"
          src="@/assets/important.svg"
          alt="Önemli İkon"
          class="important-icon"
        />

        <b>Tür:</b>
        <span class="type"> {{ announcement.type }}</span>
        <span class="create-time">{{ formatDateTime(announcement.created_at) }}</span>
      </div>

      <div class="announcement-content">
        <p v-if="announcement.services" class="services">
          <b>Hizmetler:</b> {{ announcement.services }}
        </p>
        <p v-if="announcement.start_time" class="start">
          <b>Başlangıç Tarihi/Saati:</b> {{ formatDateTime(announcement.start_time) }}
        </p>
        <p v-if="announcement.end_time" class="end">
          <b>Bitiş Tarihi/Saati:</b> {{ formatDateTime(announcement.end_time) }}
        </p>
        <p v-if="announcement.duration" class="duration">
          <b>Süre:</b> {{ announcement.duration }} dakika
        </p>
        <p v-if="announcement.headers" class="headers">
          <b>Başlık(lar):</b> {{ announcement.headers }}
        </p>
        <p v-if="announcement.details" class="details">
          <b>Detaylar:</b> <br />
          <span v-html="announcement.details"></span>
        </p>
        <p
          v-if="announcement.status"
          :class="['status', getStatusColor(announcement.status)]"
        >
          <b>Durum:</b> {{ mapStatusToTurkish(announcement.status) }}
        </p>
        <p v-if="announcement.completed_note" class="completed-note">
          <b>Durum Notları:</b> {{ announcement.completed_note }}
        </p>
      </div>
    </div>
  </div>

  </div>

</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      announcements: [],
    };
  },
  async mounted() {
    await this.fetchAnnouncements();
  },
  methods: {
    async fetchAnnouncements() {
      try {
        const response = await axios.get("/auth/announcements");
        if (response.data) {
          this.announcements = response.data;
        }
      } catch (error) {
        console.error("Duyurular alınırken hata oluştu:", error);
      }
    },
    formatDateTime(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toLocaleString("tr-TR", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    getStatusColor(status) {
      if (status === "Tamamlandı") return "status-completed";
      if (status === "Devam Ediyor") return "status-in-progress";
      if (status === "Yaklaşan") return "status-upcoming";
      if (status === "İptal Edildi") return "status-cancelled";
      return "";
    },
    mapStatusToTurkish(status) {
      const statusMap = {
        "Upcoming": "Yaklaşan",
        "In Progress": "Devam Ediyor",
        "Completed": "Tamamlandı",
        "Cancelled": "İptal Edildi",
      };
      return statusMap[status] || status;
    },
  },
};
</script> 
  

  <style scoped>

  .head {
    margin-top: 10em;
    text-align: center;
  }
  .title {
     font-size: 1.8em;
     font-weight: 300; 
     margin-bottom: 0;
   } 
  .announcements-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    justify-content: space-around;
    padding: 1em 0 10em 0;
  }
  
  .announcement-card {
    background-color: #070707;
    padding: 1rem;
    border: none;
    border-radius: 8px;
    width:49%;
    box-sizing: border-box;
    transition: background-color 0.4s ease;
  }
  
  .announcement-card:hover {
    background-color: #000;
  }
  .announcement-header {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
  }
  
  .type {
    font-weight: 600;
    color: #065959;
    margin-left: 5px;
  }
  .create-time { 
    margin-left: 5px;
    font-size: 0.8em;
  }
  
  .announcement-content p {
    margin: 10px 0;
  }
  
  .details span {
    white-space: pre-wrap;
  }
  
  .status {
    font-weight: bold;
  }
  
  .status-completed {
    color: #127750;
  }
  
  .status-in-progress {
    color: #007999;
  }
  
  .status-upcoming {
    color: #917e5c;
  }
  .status-cancelled {
    color: #953342;
  }
  .completed-note { 
    color: #3e4f56;
  }
  .important-icon {
     width: 24px; 
     height: 24px;
     margin-right: 5px; 
   }
  @media (max-width: 768px) {
    .announcements-container {
      flex-direction: column;
    }
  
    .announcement-card {
      width: 100%;
    }
  }
  </style>