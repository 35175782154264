<template>
    <div v-if="showCreateTestWizard" class="modal-overlay">
      <div class="modal wizard">
        <!-- Kapatma ikonu -->
        <button class="close-button" @click="closeCreateTestModal">×</button>
  
        <!-- Adım 1: Test İsmi -->
        <div v-if="currentStep === 1">
          <h5>Test İsmi</h5>
          <p class="description">Hazırlayacağınız test için bir isim girin.</p>
          <input 
            v-model="form.test_name" 
            type="text" 
            placeholder="Test ismi girin"
            class="test-name-input"
          />
          <p v-if="showNameWarning" class="warning">Lütfen bir test ismi girin.</p>
          <div class="button-container">
            <button class="secondary" @click="closeCreateTestModal" type="button">İptal</button>
            <button 
              class="primary" 
              @click="goToNextStep" 
              :disabled="!form.test_name">
              İleri
            </button>
          </div>
        </div>
  
        <!-- Adım 2: Eğitim Seçimi -->
        <div v-else-if="currentStep === 2">
          <h5>Eğitimleri Seç</h5>
          <p class="description">Soruların hangi alanlarla ilgili olacağını seçin.</p>
          <div class="education-checkboxes">
            <label v-for="edu in educations" :key="edu.education_id" class="checkbox-label">
              <input type="checkbox" :value="edu.education_id" v-model="form.education_ids" class="custom-checkbox" />
              <span class="checkmark"></span>
              <span class="edu-name">{{ edu.name }}</span>
            </label>
          </div>
          <p v-if="showEducationWarning" class="warning">Lütfen bir eğitim seçin.</p>
          <div class="button-container">
            <button class="secondary" @click="closeCreateTestModal" type="button">İptal</button>
            <button class="primary" @click="goToNextStep" :disabled="form.education_ids.length === 0">İleri</button>
          </div>
        </div>
  
        <!-- Adım 3: Zorluk Seviyesi -->
        <div v-else-if="currentStep === 3">
          <h5>Zorluk Seviyesi</h5>
          <p class="description">Testin genel zorluk seviyesini belirleyin.</p>
          <select v-model="form.difficulty_level" class="select-input">
            <option :value="1">Kolay</option>
            <option :value="2">Orta</option>
            <option :value="3">Zor</option>
          </select>
          <div class="button-container">
            <button class="secondary" @click="goToPreviousStep">Geri</button>
            <button class="primary" @click="goToNextStep">İleri</button>
          </div>
        </div>
  
        <!-- Adım 4: Soru Sayısı -->
        <div v-else-if="currentStep === 4">
          <h5>Soru Sayısı</h5>
          <p class="description">Testte kaç soru olacağını seçin.</p>
          <select v-model="form.question_count" class="select-input">
            <option :value="5">5</option>
            <option :value="10">10</option>
            <option :value="20">20</option>
            <option :value="50">50</option>
          </select>
          <div class="button-container">
            <button class="secondary" @click="goToPreviousStep">Geri</button>
            <button class="primary" @click="goToNextStep">İleri</button>
          </div>
        </div>
  
        <!-- Adım 5: Süre Seçimi -->
        <div v-else-if="currentStep === 5">
          <h5>Süre Seçimi</h5>
          <p class="description">Testin süre sınırını belirleyin. 0 seçerseniz süresiz.</p>
          <select v-model="form.duration_minutes" class="select-input">
            <option :value="0">Süresiz</option>
            <option :value="10">10 dk</option>
            <option :value="20">20 dk</option>
            <option :value="45">45 dk</option>
            <option :value="120">120 dk</option>
          </select>
          <div class="button-container">
            <button class="secondary" @click="goToPreviousStep">Geri</button>
            <button class="primary" @click="createTest">Testi Oluştur</button>
          </div>
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    props: {
      showCreateTestWizard: { type: Boolean, default: false },
      educations: { type: Array, default: () => [] }
    },
    data() {
      return {
        currentStep: 1,
        form: {
          test_name: '',
          education_ids: [],
          difficulty_level: 1,
          question_count: 5,
          duration_minutes: 0
        },
        showNameWarning: false,
        showEducationWarning: false
      };
    },
    methods: {
        closeCreateTestModal() {
            this.$emit('close');
        },
        goToNextStep() {
            if (this.currentStep === 1 && !this.form.test_name) {
                this.showNameWarning = true;
                return;
            }
            if (this.currentStep === 2 && this.form.education_ids.length === 0) {
                this.showEducationWarning = true;
                return;
            }
            this.showNameWarning = false;
            this.showEducationWarning = false;
            this.currentStep++;
        },
        goToPreviousStep() {
            this.currentStep--;
        },
        resetForm() {
            this.currentStep = 1;
            this.form = {
                test_name: '',
                education_ids: [],
                difficulty_level: 1,
                question_count: 5,
                duration_minutes: 0
            };
            this.showNameWarning = false;
            this.showEducationWarning = false;
        },
        async createTest() {
            try {
                await axios.post('/education/create-test', {
                    test_name: this.form.test_name,
                    education_ids: this.form.education_ids,
                    difficulty_level: parseInt(this.form.difficulty_level),
                    question_count: parseInt(this.form.question_count),
                    duration_minutes: parseInt(this.form.duration_minutes)
                }); 
                this.$emit('testCreated');
                this.closeCreateTestModal();
            } catch (error) {
                console.error('Test oluşturulurken hata:', error);
            }
        }
    }
    
  }
  </script>
  
  <style scoped> 
  .modal {
    background: #000000;
    padding: 20px;
    max-width: 600px;
    width: 100%;
    border: 1px solid #022121;
    border-radius: 10px;
    position: relative;
  }
  .close-button {
    position: absolute;
    top: 10px; right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    line-height: 1; 
    cursor: pointer;
  }
  h4 {
    margin-bottom: 20px;
  }
  h5 {
    font-size: 14px;
    margin-bottom: 5px;  
  }
  .description {
    font-size: 11px;
    color: #aaa;
    margin-bottom: 15px;
  }
  .warning {
    color: red;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  button.primary {
    background-color: #106e35; 
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  button.primary:disabled {
    background-color: #0b3018;
    cursor: not-allowed;
  }
  button.secondary {
    background-color: #650101; 
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  button.secondary:hover {
    background-color: #9b0101;
  }
  .test-name-input,
  .select-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #444;
    background: #111; 
    margin-bottom: 10px;
  }
  
  .education-checkboxes {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer; 
    line-height: 18px; 
    padding-left: 30px; 
  }
  
  .checkbox-label .custom-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .checkbox-label .checkmark {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    background-color: #111;
    border: 2px solid #444;
    border-radius: 3px;
    box-sizing: border-box;
  }
  
  .checkbox-label .custom-checkbox:checked ~ .checkmark {
    background-color: #106e35;
    border-color: #106e35;
  }
  
  .edu-name {
    margin-left: 10px;
  }
  </style>
  