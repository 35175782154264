<template>
  <div>
    <footer class="footer">
      <div class="footer-column"> 
        <p  @click="navigateTo('/hakkimizda')" class="menu-item">Hakkımızda</p>
        <p  @click="navigateTo('/urunler')" class="menu-item">Ürünler</p>
        <p  @click="navigateTo('/mobiluygulamalar')" class="menu-item">Aplikasyonlar</p>
        <p  @click="navigateTo('/ucretsizegitim')" class="menu-item">Eğitimler</p> 
      </div>
      <div class="footer-column"> 
        <p  @click="navigateTo('/kullanim-kosullari')" class="menu-item">Kullanım koşulları</p>
        <p  @click="navigateTo('/cerez-politikasi')" class="menu-item">Çerez politikası</p>
        <p  @click="navigateTo('/veri-gizliligi')" class="menu-item">Veri gizliliği</p>
        <p  @click="navigateTo('/sozlesmeler')" class="menu-item">Sözleşmeler</p> 
      </div>
      <div class="footer-column">  
        <p  @click="navigateTo('/iletisim')" class="menu-item">İletişim</p>
        <p  @click="navigateTo('/sss')" class="menu-item">SSS</p>
        <p  @click="navigateTo('/sertifikalar')" class="menu-item">Standartlar</p> 
      </div>
      <div class="footer-column">  
        <img src="@/assets/icon/footer-logo.svg" class="logo" alt="Logo">
        <div class="icon-row">
          <img @click="openLink('https://www.instagram.com/noahpmushop')" src="@/assets/icon/instagram.svg" class="icon" alt="Instagram">
          <img @click="openLink('https://wa.me/+905331348139')" src="@/assets/icon/whatsapp.svg" class="icon" alt="WhatsApp">
          <img @click="navigateTo('/sertifikalar')" src="@/assets/icon/docs.svg" class="icon" alt="Docs">
          <img @click="navigateTo('/mobiluygulamalar')" src="@/assets/icon/download.svg" class="icon" alt="Download">
        </div>
      </div>
    </footer>
    <div class="sticky-bottom"><p>©Noah {{ currentYear }} Tüm hakları saklıdır.</p></div>
  </div>
</template>

<script>
export default {
  name: 'MainFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    openLink(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>
.footer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 0.7rem;
  margin-bottom: 1rem;
  user-select: none;
  z-index: 1005;
}

.logo {
  width: 120px;
  margin-bottom: 0.5rem;
}

.icon-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.icon { 
  max-height: 30px;
  cursor: pointer;
}

.sticky-bottom {
  position: fixed;
  bottom: 0;
  width: 100%; 
  background-color: #000000;
  padding: 0.5rem 0; 
  padding-right: 32px; 
  text-align: right;
}

.sticky-bottom p {  
  padding: 0;
  margin: 0;
  padding-right: 32px; 
  font-size: 0.5rem; 
} 

.menu-item {   
  font-size: 0.7rem;
  color: #cecece;
}

.menu-item:hover { 
  color: #ffffff;
  cursor: pointer;
}
.footer-column:last-child {
  opacity: 0.8;
}

.footer-column p {
  margin: 0.4em 0;
}
@media (max-width: 768px) {
  .footer {
    grid-template-columns: repeat(2, 1fr);
  }

  .sticky-bottom p {  
    padding: 0;
    margin: 0;
    padding-right: 32px; 
    font-size: 0.5rem;
  }

  .icon-row {
    justify-content: space-between; 
  }
}
</style> 